import { MODULE_NAME, PERMISSION } from './permission.constant';
// export const menuItems=[
//   {}
// ]

export const menuItems = [
  {
    permission: PERMISSION.DASHBOARD_READ,
    icon: 'fas fa-tachometer-alt',
    title: 'Dashboard',
    keyId: 'dashboard',
    isCollapsing: true,
    children: [
      // { title: "Dashboard v1", path: "../../index.html" },
      // { title: "Dashboard v2", path: "../../index2.html" },
      // { title: "Dashboard v3", path: "../../index3.html" },
    ],
  },
  {
    permission: PERMISSION.DASHBOARD_READ,
    icon: 'fas fa-edit',
    title: 'Forms',
    children: [
      { title: 'General Elements', path: '../forms/general.html' },
      { title: 'Advanced Elements', path: '../forms/advanced.html' },
      { title: 'Editors', path: '../forms/editors.html' },
      { title: 'Validation', path: '../forms/validation.html' },
    ],
  },

  {
    permission: PERMISSION.USER_READ,
    icon: 'fas fa-user-cog',
    title: 'Users',
    path: '/users/user',
    children: [
      {
        title: 'User Management',
        path: '/users/user',
        permission: PERMISSION.USER_READ,
      },
      {
        title: 'Role & Permission',
        path: '/users/role',
        permission: PERMISSION.ROLE_READ,
      },
    ],
  },

  {
    permission: PERMISSION.LEADS_READ,
    iconComponent: 'leadsIcon',
    icon: '',
    title: 'Leads',
    path: '/leads',
  },

  {
    permission: PERMISSION.LEADS_READ,
    iconComponent: 'jobcardIcon',
    icon: '',
    title: 'Jobcard',
    path: '/job-cards',
  },

  {
    permission: PERMISSION.LEADS_READ,
    iconComponent: 'quotationIcon',
    icon: '',
    title: 'Quotation',
    path: '/quotations',
  },

  {
    permission: MODULE_NAME.CONFIGURATION,
    icon: 'fas fa-cog',
    title: 'Configurations',
    path: '/configurations',
    children: [
      {
        title: 'Sources',
        path: '/configurations/sources',
        permission: PERMISSION.SOURCES_READ,
      },
      {
        title: 'Service Type',
        path: '/configurations/service-types',
        permission: PERMISSION.SERVICE_TYPE_READ,
      },
      {
        title: 'Position',
        path: '/configurations/positions',
        permission: PERMISSION.POSITION_READ,
      },
      {
        title: 'Brand',
        path: '/configurations/brands',
        permission: PERMISSION.BRAND_READ,
      },
      {
        title: 'Model',
        path: '/configurations/models',
        permission: PERMISSION.MODEL_READ,
      },
      {
        title: 'Color',
        path: '/configurations/colors',
        permission: PERMISSION.COLOR_READ,
      },
      {
        title: 'Term & Conditions',
        path: '/configurations/term-condition',
        permission: PERMISSION.TERM_CONDITIONS_READ,
      },
      {
        title: 'Address Yescar',
        path: '/configurations/address-yescar',
        permission: PERMISSION.TERM_CONDITIONS_READ,
      },
    ],
  },
];
export const filterMenu = (optionFilter = []) =>
  menuItems.filter(function f(o) {
    if (optionFilter.find((route) => route == o.permission)) return true;

    if (o.children) {
      return (o.children = o.children.filter(f)).length;
    }
  });

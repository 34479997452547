import { PERMISSION } from '@/constants/permission.constant';

// configurations/term-condition/create
export const termCondition = [
  {
    path: '/configurations/term-condition/create',
    name: 'term-condition-create',
    component: () => import('@/views/configurations/term-condition/FromTermCondition.vue'),
    meta: {
      requiresAuth: true,
      permissions: [PERMISSION.TERM_CONDITIONS_CREATE],
    },
  },

  {
    path: '/configurations/term-condition/edit/:id',
    name: 'term-condition-edit',
    component: () => import('@/views/configurations/term-condition/FromTermConditionEdit.vue'),
    meta: {
      requiresAuth: true,
      permissions: [PERMISSION.TERM_CONDITIONS_UPDATE],
    },
  },
];

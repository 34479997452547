import _ from 'lodash';
import Vue from 'vue';
Vue.directive('hasPermission', {
  async inserted(el, binding, vnode) {
    const userPermissions = vnode.context.$store.getters['getUserPermission'];
    console.log({ userPermissions });

    const requiredPermission = binding.value;
    const hasAction = _.some(
      userPermissions,
      (item) => _.includes(item.action, requiredPermission)

      //  ||
      // _.includes(MODULE_NAME, requiredPermission)
    );
    const hasModuleName = userPermissions.find((m) => m.moduleName == requiredPermission);

    console.log({ hasAction });

    if (hasAction || hasModuleName) {
      el.style.display = ''; // Hide the element
    } else {
      el.style.display = 'none'; // Show the element
    }
  },

  async update(el, binding, vnode) {
    const userPermissions = vnode.context.$store.getters['getUserPermission'];
    console.log({ userPermissions });

    const requiredPermission = binding.value;
    const hasAction = _.some(
      userPermissions,
      (item) => _.includes(item.action, requiredPermission)

      //  ||
      // _.includes(MODULE_NAME, requiredPermission)
    );
    const hasModuleName = userPermissions.find((m) => m.moduleName == requiredPermission);

    console.log({ hasAction });

    if (hasAction || hasModuleName) {
      el.style.display = ''; // Hide the element
    } else {
      el.style.display = 'none'; // Show the element
    }
  },

  bind(el, binding, vnode) {
    const checkPermission = () => {
      const userPermissions = vnode.context.$store.getters['getUserPermission'];
      console.log({ userPermissions });

      const requiredPermission = binding.value;
      const hasAction = _.some(
        userPermissions,
        (item) => _.includes(item.action, requiredPermission)

        //  ||
        // _.includes(MODULE_NAME, requiredPermission)
      );
      const hasModuleName = userPermissions.find((m) => m.moduleName == requiredPermission);

      console.log({ hasAction });

      if (hasAction || hasModuleName) {
        el.style.display = ''; // Hide the element
      } else {
        el.style.display = 'none'; // Show the element
      }
    };

    checkPermission();

    // Watch for changes in user permissions
    vnode.context.$store.watch(
      (state) => state.user.permission,
      () => {
        checkPermission();
      }
    );
  },
});

import api from '@/services/AxiosConfig';
const { CITY_URL = 'dropdown/get-provinces', ADVISOr_URL = 'dropdown/get-user-advisors' } = {};
export class HelperService {
  static getCity() {
    return api
      .get(`${CITY_URL}`)
      .then((res) => res.data?.map((m) => ({ text: m.nameEn, value: m.id })) || [])
      .catch((er) => {
        console.log({ er });
        return [];
      });
  }

  static getAdvisor() {
    return api
      .get(`${ADVISOr_URL}`)
      .then((res) => res.data?.map((m) => ({ text: m.name, value: m.id })) || [])
      .catch((er) => {
        console.log({ er });
        return [];
      });
  }
}

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Vue from 'vue';
import VModal from 'vue-js-modal';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import App from './App.vue';
import router from './router';
import './share/helper'; // Import the global extension
import store from './store';
// Importing Styles
import 'admin-lte/dist/css/adminlte.min.css?v=3.2.0';
import 'admin-lte/plugins/fontawesome-free/css/all.min.css';
import 'admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'vue-simple-suggest/dist/styles.css';
import './style.scss';
// Importing Scripts
import 'admin-lte/dist/js/adminlte.min.js?v=3.2.0';
import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js';
import 'admin-lte/plugins/jquery/jquery.min.js';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.full.min.js';
// import Multiselect from "vue-multi-select";
// Import the CSS

// Global Components
import Multiselect from 'vue-multiselect';
import AppFooter from './components/partials/AppFooter.vue';
import AppNavBar from './components/partials/AppNavBar.vue';
import AppSideBar from './components/partials/AppSideBar.vue';
import ControlSideBar from './components/partials/ControlSideBar.vue';
// Global Mixin
import DateRangePicker from 'vue2-daterange-picker';
import GlobalSizeMixin from './constants/GlobalSizeMixin';
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
// User Activity Event Setup
import { EventBus } from '@/eventBus';
import CKEditor from '@ckeditor/ckeditor5-vue';
import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import VueSimpleSuggest from 'vue-simple-suggest';
// import './components/AppControl';
import BaseFormGroup from './components/BaseControl/BaseFormGroup.vue';
import { IS_PERMISSION } from './constants/permission.constant';
import './directives/hasPermission';
import PermissionDirective from './directives/permission';
import { formatCurrency } from './utils/formatCurrency';
Vue.config.productionTip = false;

Vue.config.devtools = (process.env.VUE_APP_ENV || '').toString() == 'development' ? true : false;

if ((process.env.VUE_APP_ENV || '').toString() == 'production') {
  console.log({ sfsf: (process.env.VUE_APP_ENV || '').toString() });

  Vue.config.errorHandler = function (err, vm, info) {
    console.log(`Error: ${err.toString()}\nInfo: ${info}`);
  };
  Vue.config.warnHandler = function (msg, vm, trace) {
    console.log(`Warn: ${msg}\nTrace: ${trace}`);
  };
}

// Define a global filter
Vue.filter('dateFormat', function (value) {
  if (value) {
    return moment(value).format('DD/MM/YY'); // Customize your date format here
  }
  return '';
});

// Define a global filter
Vue.filter('dateTimeFormat', function (value) {
  if (value) {
    return moment(value).format('DD/MM/YY - HH:mm a'); // Customize your date format here
  }
  return '';
});
Vue.use(CKEditor);
// Use Plugins
Vue.use(router);
Vue.use(store);
Vue.use(Toast, {
  // Your toast options here
});
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VModal);

// Attach PERMISSION to prototype
Vue.prototype.$PERMISSION = IS_PERMISSION;
Vue.prototype.$uuidv4 = uuidv4;

Vue.directive('permission', PermissionDirective);

// Register Global Components
Vue.component('AppNavBar', AppNavBar);
Vue.component('AppSideBar', AppSideBar);
Vue.component('AppFooter', AppFooter);
Vue.component('ControlSideBar', ControlSideBar);

Vue.component('multi-select', Multiselect);
Vue.component('date-range-picker', DateRangePicker);
Vue.component('base-form-group', BaseFormGroup);
Vue.component('vue-simple-suggest', VueSimpleSuggest);
// Vue.component("multi-select", VueMultiselect);
// Mixin
Vue.mixin(GlobalSizeMixin);
Vue.prototype.$moment = moment;
Vue.filter('currency', formatCurrency);
// Create Vue instance
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

const events = ['mousemove', 'keydown', 'click', 'scroll'];
const resetTimer = _.debounce(() => {
  EventBus.$emit('user-activity');
}, 500);

events.forEach((event) => {
  window.addEventListener(event, resetTimer);
});

const cleanupListeners = () => {
  events.forEach((event) => {
    window.removeEventListener(event, resetTimer);
  });
};

window.addEventListener('beforeunload', cleanupListeners);

import api from '@/services/AxiosConfig';
import moment from 'moment';
import { STATUSES_JOBCARD } from './jobcard.constant';
export class MJobCards {
  constructor({ leadId, plateNumber = '', mileage = '', credit = '' }) {
    this.leadId = leadId;
    this.plateNumber = plateNumber;
    this.mileage = mileage;
    this.credit = credit;
  }
}

export class JobCardModel {
  constructor(data) {
    this.id = data.id || null;
    this.leadId = data.leadId || null;
    this.jobCardDate = data.jobCardDate || null;
    this.vinNumber = data.vinNumber || '';
    this.plateNumber = data.plateNumber || '';
    this.mileage = data.mileage || '';
    this.warranty = data.warranty || '';
    this.warantyId=data.warranty || '';
    this.credit = data.credit || '';
    this.remark = data.remark || '';
    this.statusCode = data.statusCode || null;
    this.status=data.status;
    this.statusName = data.statusName || '';
    this.customerName = data.customerName || '';
    this.phoneNumber = data.phoneNumber || '';
    this.serviceTypes = data.serviceTypes || [];
    this.serviceAdvisors = data.serviceAdvisors || [];
    this.leadsComplains = data.leadsComplains || [];
    this.modelName = data.modelName || '';
    this.brandId = data.brandId || null;
    this.brandName = data.brandName || '';
    this.modelId = data.modelId || null;
    this.createdBy = data.createdBy || '';
    this.createdAt = data.createdAt || '';
    this.lastModifiedBy = data.lastModifiedBy || '';
    this.lastModifiedAt = data.lastModifiedAt || '';
    this.jobcardId = data.jobCardId || '';

  }

  getItem(data) {
    return { ...new JobCardModel(data) };
  }
}
const REMARK="remark";
const _URL_API = 'job-cards';
export class JobCardsService {
  static converJobcardToQuotation({ payload, id }) {
    const parmsBody = { ...payload };
    if (!id)
      return { error: '\"id\": is required ,url is not found' };

    return api
      .put(`${_URL_API}/jobcart-to-quotation/${id}`, parmsBody)
      .then((res) => ({ success: res?.data }))
      .catch((er) => {
        return { error: er };
      });
  }

  static convertLeadsToJobCard(payload) {
    const parmsBody = { ...payload };
    return api
      .post(`${_URL_API}`, parmsBody)
      .then((res) => ({ success: res?.data }))
      .catch((er) => {
        return { error: er };
      });
  }
  
  static getDropdowns() {
    const findAllDropdown = api
      .get(_DROPDOWNLIST)
      .then((d) => ({ success: d.data }))
      .catch((er) => ({ error: er }));
    return findAllDropdown;
  }

  static listJobCard({ queryParam }) {
    const _queryParam = { ...queryParam };
    return api
      .get(`${_URL_API}`, { params: _queryParam })
      .then((res) => ({ success: res.data }))
      .catch((er) => {
        return { error: er };
      });
  }

  static getJobCardDetail({ id }) {
    return api
      .get(`${_URL_API}/${id}`)
      .then((res) => {
        return { success: new JobCardModel(res.data) };
      })
      .catch((er) => {
        console.log(er);
        return { error: er };
      });
  }

  static update({ id, body }) {
    return api
      .put(`${_URL_API}/${id}`, { ...body })
      .then((res) => {
        return { success: new JobCardModel(res.data) };
      })
      .catch((er) => {
        console.log(er);
        return { error: er };
      });

  }
    
  static async downloadExcel({  params,columnsQuery }) {
      try {
          const response = await api.get(`${_URL_API}/excel/download?columns=${columnsQuery}`, {
            params, 
            responseType: 'blob', // Important: Set the response type to 'blob' to handle binary data
          });

          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          // Create a link element for download
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'jobcard.xlsx'; 
          link.click();

          // Clean up the URL object
          window.URL.revokeObjectURL(link.href);

          console.log('Excel file downloaded successfully!');
      } catch (error) {
          console.error('Error downloading the Excel file:', error);
      }
  }

  static async getRemark(id) {
    return api
      .get(`${_URL_API}/${REMARK}/${id}`)
      .then((d) => ({ success: d.data }))
      .catch((er) => ({ error: er }));
  }
  static async fileUploadJobcard(payload,jobcardId){
        const parmsBody = { ...payload };
      return api
        .post(`${_URL_API}/${jobcardId}/files`, parmsBody)
        .then((res) => ({ success: res?.data || "ok" }))
        .catch((er) => {
          return { error: er };
        });
    }


}

const mileageUnit = (value)=>{
  const [mileage,unit] = value.split(':') || [];
  return {mileage,unit};
}

export function MappFormData({ data, citiesOption, STATUS_LIST }) {

  const { brand, source, serviceTypes, serviceType, serviceAdvisors, followUpDate, bookedDate, model, color, status, ...propsData } = {
    ...new JobCardModel(data),
  };

  const findServiceAdvisor= serviceAdvisors?.length ? serviceAdvisors?.
  map((m) => ({ text: m.fullName, value: m.id })) : null;

  const findServieType = serviceTypes && Array.isArray(serviceTypes) 
                        ? serviceTypes.map((m) => ({value:m.id,text:m.name})) 
                        : serviceTypes;

  const cities = citiesOption.find((m) => m.value == data.cityId);
  const  {mileage, unit} = mileageUnit(data.mileage);

  const formModel = {
    ...(propsData || {}),
    ...data,
    jobcardId: data.jobcardId,
    serviceAdvisorIds: findServiceAdvisor.map((m) => m.value),
    mileage,
    status: data.status,
    unit,
    serviceTypeIds: findServieType?.map((m) => m.value) || [],
  }


  return formModel;
}

export function getFormDataSubmit(formValue) {

  const {
    customerName,
    phoneNumber,
    plateNumber,
    brandId,
    modelId,
    mileage,
    unit,
    year,
    serviceTypeIds = [],
    serviceAdvisorIds = [],
    leadsComplains = [],
    remark,
    statusCode,
    isActive,
    vinNumber,
    source,
    serviceType,
    serviceAdvisor,
    status,
    jobcardId,
    warranty,
    credit,
  } = {
    ...formValue,
    // warranty: formValue.waranties?.value || 0,
    // serviceTypeIds: formValue?.serviceType?.map((m) => m?.value).filter(m => m) || [],
    // serviceAdvisorIds: formValue?.serviceAdvisor?.map((m) => m?.value)?.filter(m => m) || [],
    leadsComplains: formValue?.leadsComplains,
    // credit: formValue?.credit?.value || 0
  };

  return {
    customerName,
    phoneNumber,
    plateNumber,
    brandId,
    modelId,
    mileage,
    unit,
    year,
    serviceTypeIds,
    serviceAdvisorIds,
    leadsComplains,
    remark,
    statusCode,
    isActive,
    vinNumber,
    source,
    serviceType,
    serviceAdvisor,
    status,
    jobcardId,
    warranty,
    credit,
  };
}

export function getStatusFormat({status}){


  const statusMap = STATUSES_JOBCARD.find(m=>m.value==status);

  return statusMap ? statusMap : {};

}
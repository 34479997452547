import { PERMISSION } from '@/constants/permission.constant';

export const addressYescar = [
  {
    path: '/configurations/address-yescar',
    name: 'address-yescar-create',
    component: () => import('@/views/configurations/address-yescar/AddressYesCarFormCreate.vue'),
    meta: { requiresAuth: true, permissions: [PERMISSION.BRAND_CREATE] },
  },

  {
    path: '/configurations/address-yescar/edit/:id',
    name: 'branchs-edit',
    component: () => import('@/views/configurations/address-yescar/AddressYesCarFormEdit.vue'),
    meta: { requiresAuth: true, permissions: [PERMISSION.BRAND_UPDATE] },
  },
];

import api from "@/services/AxiosConfig";



export const fetchData = async (endpoint, params = {}) => {
  try {
    const response = await api.get(`${endpoint}`, { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
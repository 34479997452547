export class CredentialService {
  static _fullNameKey = 'fullName';
  static _usernameKey = 'username';
  static _userTypeKey = 'userType';
  static _permission = 'permission';
  static _type = '';
  static _accessTokenKey = 'accessToken';

  static getCredential() {
    const fullName = localStorage.getItem(CredentialService._fullNameKey) || '';
    const username = localStorage.getItem(CredentialService._usernameKey) || '';
    const userType = localStorage.getItem(CredentialService._userTypeKey);
    const accessToken = localStorage.getItem(CredentialService._accessTokenKey) || '';
    const permission = JSON.parse(localStorage.getItem(CredentialService._permission));
    return {
      fullName,
      username,
      permission,
      // userType: userType ? JSON.parse(userType) : null,
      accessToken,
    };
  }

  static setCredential({ username, userType, accessToken, fullName, type, permission }) {
    localStorage.setItem(CredentialService._fullNameKey, fullName);
    localStorage.setItem(CredentialService._usernameKey, username);
    localStorage.setItem(CredentialService._userTypeKey, JSON.stringify(userType));
    localStorage.setItem(CredentialService._accessTokenKey, accessToken);
    localStorage.setItem(this._type, type);
    localStorage.setItem(CredentialService._permission, JSON.stringify(permission));
  }

  static clearCredential() {
    localStorage.removeItem(CredentialService._usernameKey);
    localStorage.removeItem(CredentialService._userTypeKey);
    localStorage.removeItem(CredentialService._accessTokenKey);
    localStorage.removeItem(CredentialService._fullNameKey);
    localStorage.removeItem(CredentialService._permission);
  }
}

// services/UserService.js
import { handleError } from '@/errorHandler';
import { CredentialService } from '@/services/CredentialService';
import axios from '@/services/DefaultApi';
import { getGlobalToast } from '@/toast';

const API_URL = 'users';

class UserService {
  constructor() {
    this.toast = getGlobalToast(); // Assuming you have a global toast setup
  }

  async getUserList(params) {
    try {
      const response = await axios.get(`${API_URL}`, { params });
      return response.data; // Make sure to return response.data
    } catch (error) {
      throw error;
    }
  }

  async createUser(userData) {
    try {
      const response = await axios.post('auth/signup', userData);
      return response;
    } catch (error) {
      if (error.response) {
        // If the error has a response, return it to be handled by the caller
        return error.response;
      } else {
        handleError(error);
      }
    }
  }

  async getUserById(userId) {
    try {
      return axios.get(`${API_URL}/${userId}`).then((d) => d.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateUser(userId, userData) {
    try {
      const response = await axios.put(`${API_URL}/${userId}`, userData);
      return response;
    } catch (error) {
      if (error.response) {
        // If the error has a response, return it to be handled by the caller
        return error.response;
      } else {
        // If there's no response, log the error and return a generic message
        handleError(error);
      }
    }
  }

  async resetPassword(userId, userData) {
    const path = 'reset-password';
    try {
      const response = await axios.put(`${API_URL}/${path}/${userId}`, userData);
      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        console.error(error);
        return {
          status: 500,
          message: 'An unexpected error occurred.',
        };
      }
    }
  }

  async getProfileById(userData) {
    return axios
      .post('users/profile', userData)
      .then((rs) => {
        return rs.data;
      })
      .catch((er) => {
        console.log(er);
        return [];
      });
  }

  async deleteUser(userId) {
    try {
      const response = await axios.delete(`${API_URL}/${userId}`);
      this.toast.success('User deleted successfully');
      return { success: response.data };
    } catch (error) {
      return { error };
    }
  }

  async getUserPermissionFields() {
    try {
      const path = 'user-modules-fields';
      const response = await axios.get(`${API_URL}/${path}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  // Group the modules based on parentModule
  groupedModules(modules) {
    console.log(modules);

    return modules.reduce((acc, module) => {
      if (!module.parentModule) {
        // If no parentModule, it's a parent module
        acc.push({ parentModule: module, subModules: [] });
      } else {
        // Find the parent module in the accumulator
        const parent = acc.find((parent) => parent.parentModule.id === module.parentModule.id);
        if (parent) {
          parent.subModules.push(module);
        } else {
          // Create a new parent module with the subModule if not found
          acc.push({
            parentModule: {
              id: module.parentModule.id,
              moduleName: module.parentModule.name,
            },
            subModules: [module],
          });
        }
      }
      return acc;
    }, []);
  }

  async getCurrentUser() {
    const credentials = CredentialService.getCredential();
    const data = {
      username: credentials.username,
    };

    try {
      const response = await axios.post('users/profile', data);
      const result = response.data;
      const user = {
        id: result.id,
        name: result.name,
      };

      return user;
    } catch (error) {
      console.error('Error fetching user profile:', error);
      return { id: null, name: null };
    }
  }
}

export default new UserService();

import { PERMISSION } from '@/constants/permission.constant';

const RoleList = () => import('@/views/role/RoleList.vue');
const CreateRole = () => import('@/views/role/CreateRole.vue');
const EditRole = () => import('@/views/role/EditRole.vue');

export const roleUserRoute = [
  {
    path: '/users/role',
    name: 'role-list',
    component: RoleList,
    meta: { requiresAuth: false, permissions: [PERMISSION.ROLE_READ] },
  },

  {
    path: '/users/role/create',
    name: 'create-role',
    component: CreateRole,
    meta: { requiresAuth: false, permissions: [PERMISSION.ROLE_CREATE] },
  },

  {
    path: '/users/role/edit',
    name: 'edit-role',
    component: EditRole,
    meta: { requiresAuth: false, permissions: [PERMISSION.ROLE_UPDATE] },
  },
];

import { PERMISSION } from '@/constants/permission.constant';

const quotationList = () => import('@/views/quotation/QuotationList.vue');
const quotCunterSlae = () => import('@/views/quotation/forms/CounterSale.vue');
const editQuotation = () => import('@/views/quotation/forms/EditQuotation.vue');
// const EditRole = () => import("@/views/role/EditRole.vue");

export const quotationRoute = [
  {
    path: '/quotations',
    name: 'quotation-list',
    component: quotationList,
    meta: { requiresAuth: false, permissions: [PERMISSION.LEADS_READ] },
  },

  {
    path: '/quotations/counter-sale',
    name: 'add-counter-sale',
    component: quotCunterSlae,
    meta: { requiresAuth: false, permissions: [PERMISSION.LEADS_READ] },
  },

  {
    path: '/quotations/edit-quotation',
    name: 'edit-quotation',
    component: editQuotation,
    meta: { requiresAuth: false, permissions: [PERMISSION.LEADS_READ] },
  },

  // {
  //   path: "/users/role/edit",
  //   name: "edit-role",
  //   component: EditRole,
  //   meta: { requiresAuth: false, permissions: [PERMISSION.ROLE_UPDATE] },
  // },
];

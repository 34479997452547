import { PERMISSION } from '@/constants/permission.constant';

const UserManagement = () => import('@/views/user/UserList.vue');
const CreateUser = () => import('@/views/user/CreateUser.vue');
const EditUser = () => import('@/views/user/EditUser.vue');
const UserProfile = () => import('@/views/user/UserProfile.vue');

export const userRoute = [
  {
    path: '/users/user',
    name: 'user-list',
    component: UserManagement,
    meta: { requiresAuth: true, permissions: [PERMISSION.USER_READ] },
  },
  {
    path: '/users/user/create',
    name: 'create-user',
    component: CreateUser,
    meta: { requiresAuth: true, permissions: [PERMISSION.USER_CREATE] },
  },
  {
    path: '/users/user/edit',
    name: 'edit-user',
    component: EditUser,
    meta: { requiresAuth: true, permissions: [PERMISSION.USER_UPDATE] },
  },
  {
    path: 'user-profile',
    name: 'user-profile',
    component: UserProfile,
    meta: { requiresAuth: true, permissions: [PERMISSION.DEFAULT] },
  },
];

// store/modules/userPermission.js

import { UserPermissionService } from '@/services/UserPermission.service';

const getDefaultState = () => {
  return {
    refreshMenu: true,
    userPermissions: {},
    userName: '',
    permissions: [],
    lastUpdated: null,
  };
};

const state = getDefaultState();

const mutations = {
  setUserPermissions(state, { userName, permissions }) {
    state.userName = userName;
    state.userPermissions[userName] = permissions;
  },

  setMenuSideRerender(state, isPage) {
    state.refreshMenu = isPage || true;
  },

  setUserPermissionsDefault(state) {
    Object.assign(state, getDefaultState());
  },

  SET_PERMISSION(state, data = []) {
    state.permissions = data;
  },

  // // Optional: If you want a mutation that can reset the state globally
  // [SET_DEFAULT](state) {
  //   Object.assign(state, getDefaultState());
  // },
};

const getters = {
  userPermissions: (state) => state.userPermissions[state.userName],

  hasPermission: (state) => (permission) => {
    return state.userPermissions[state.userName].includes(permission);
  },

  menuSideRerender: (state) => state.refreshMenu || true,
  getPermissions: (state) => state.permissions,
};

const actions = {
  resetUserPermissionsState({ commit }) {
    commit('setUserPermissionsDefault');
  },

  setRefreshMenu({ commit }, value) {
    commit('setMenuSideRerender', value);
  },

  // Optional: Use SET_DEFAULT mutation for global reset
  async getPermissionsUsers({ commit }) {
    const now = Date.now();
    if (!state.lastUpdated || now - state.lastUpdated > 30000) {
      const findAllPermission = await UserPermissionService.apiGetUserPermission();

      // 30 seconds threshold
      // commit("SET_DATA", { data: response.data, timestamp: now });
      commit('SET_PERMISSION', { data: findAllPermission, timestamp: now });
      return findAllPermission;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

import { PERMISSION } from '@/constants/permission.constant';

export const models = [
  {
    path: '/configurations/models/create',
    name: 'models-create',
    component: () => import('@/views/configurations/models/FormCreate.vue'),
    meta: { requiresAuth: true, permissions: [PERMISSION.MODEL_CREATE] },
  },

  {
    path: '/configurations/models/edit/:id',
    name: 'models-edit',
    component: () => import('@/views/configurations/models/FormEdit.vue'),
    meta: { requiresAuth: true, permissions: [PERMISSION.MODEL_UPDATE] },
  },
];

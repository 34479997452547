<template>
  <li :class="['nav-item', { 'is-collapsed': isCollapsing }]" v-permission="permission">
    <router-link :to="path" class="nav-link d-flex" :class="{ 'is-active': isActived }"
      @handle-click-menu="toggleMenus">
      <i v-if="iconClass" :class="`nav-icon ${iconClass}`"></i>
      <IconLead :icon-class="'pr-3'" v-if="iconComponent == 'leadsIcon'"></IconLead>
      <IconWallet :icon-class="'pr-3'" v-if="iconComponent == 'jobcardIcon'"></IconWallet>
      <IconQuotation :icon-class="'pr-3'" v-if="iconComponent == 'quotationIcon'"></IconQuotation>
      <p class="pl-2">
        {{ title }}
        <i v-if="hasChildren" class="fas fa-angle-right right"></i>
      </p>
    </router-link>

    <ul v-if="hasChildren" class="nav nav-treeview pl-3">
      <MenuItem v-for="(item, index) in children" :title="item.title" :icon-class="item.iconClass"
        :children="item.children" :path="item.path" :is-open="isOpen" :is-collapsing="isCollapsing"
        :is-actived="item.isActived" v-permission="item.permission" :key="item.path || index.toString()"
        :iconComponent="item.iconComponent" />
    </ul>
    <ul v-else class="nav nav-treeview pl-3"></ul>
  </li>
</template>

<script>
import IconLead from '../Icons/IconLead.vue';
import IconQuotation from '../Icons/IconQuotation.vue';
import IconWallet from '../Icons/IconWallet.vue';
import MenuItem from './MenuItem.vue';

export default {
  name: 'MenuItem',
  components: {
    MenuItem,
    IconLead,
    IconWallet,
    IconQuotation,
  },
  props: {
    isOpen: { type: String, default: '' },
    isCollapsing: { type: Boolean, default: false },
    path: { type: String, default: '/' },
    permission: { type: String, default: '' },
    title: { type: String, required: true },
    iconClass: { type: String, default: '' },
    children: { type: Array, default: () => [] },
    iconComponent: { type: String, default: () => '' },
    isActived: { type: Boolean, default: false },
  },
  computed: {
    checkOpenMenu() {
      return this.title == this.isOpen;
    },

    hasChildren() {
      return this.children && this.children.length > 0;
    },
  },
  methods: {
    toggleMenus() {
      this.$emit('handle-click-menu', this.title);
    },
  },
};
</script>

<style scoped>
@keyframes expandHeight {
  0% {
    max-height: 0;
    height: 0;
  }

  100% {
    max-height: 500px;
    /* Adjust according to your content */
    height: 100%;
  }
}

@keyframes collapseHeight {
  0% {
    max-height: 500px;
    /* Adjust according to your content */
    height: 100%;
  }

  100% {
    max-height: 0;
    height: 0;
  }
}

.nav--treeview {
  overflow: hidden;
  height: 0;
  max-height: 0;
  transition:
    max-height 0.3s ease-in-out,
    height 0.3s ease-in-out;
}

.nav-item.open>.nav--treeview {
  animation: expandHeight 0.3s ease-in-out forwards;
}

.nav-item.collapsing>.nav--treeview {
  animation: collapseHeight 0.3s ease-in-out forwards;
}
</style>

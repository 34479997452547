import { LeadsService } from '../leads/leads.service';
import { JobCardsService } from './jobcard.service';
const ACTON_NAME = {
  SET_LIST: 'SET_LIST',
  SET_DROPDOWN_LIST: 'SET_DROPDOWN_LIST',
  SET_DETAIL: 'SET_DETAIL',
  RESET: 'RESET',
  SET_REMARK: 'SET_REMARK',
  SET_ITEM_VIEW:'SET_ITEM_VIEW'
};

const clearState = () => ({
  drowpdownList: [],
  list: {},
  optionFilter: { pageNo: 0, pageSize: 25 },
  loading: false,
  detail: {},
  remarks: [],
  itemView: {}
});

const state = clearState();

const mutations = {
  [ACTON_NAME.SET_LIST](state, data) {
    state.list = data;
  },

  [ACTON_NAME.SET_DROPDOWN_LIST](state, data) {
    state.drowpdownList = data;
  },

  [ACTON_NAME.SET_DETAIL](state, data) {
    state.detail = data;
  },

  [ACTON_NAME.RESET](state) {
    state = clearState();
  },
  [ACTON_NAME.SET_LIST](state, data) {
    state.list = data;
  },

  [ACTON_NAME.SET_REMARK](state, data) {
    state.remarks = data;
  },
  [ACTON_NAME.SET_ITEM_VIEW](state,data){
    state.itemView = data;
  }
};

const actions = {
  async drowpdownListAction({ commit }) {
    const list = await LeadsService.getDropdownLeads();
    commit(ACTON_NAME.SET_DROPDOWN_LIST, list);
  },

  async getDetailAction({ commit, state }, { id }) {
    const data = await JobCardsService.getJobCardDetail({ id });
    commit(ACTON_NAME.SET_DETAIL, data);
    return data;
  },

  async resetState({ commit }) {
    commit(ACTON_NAME.RESET);
  },

  async jobCardsActionList({ commit, state }, { params }) {
    const { brandId, sourceId, phoneNumber, plateNumber, status: statusCode ,} = params;
    state.optionFilter = {
      ...state.optionFilter,
      ...params,

      statusCode,
      brandId,
      sourceId,
      phoneNumber,
      plateNumber,
    };
    state.loading = true;
    const jobCards = await JobCardsService.listJobCard({
      queryParam: state.optionFilter,
    });

    commit(ACTON_NAME.SET_LIST, jobCards);
    state.loading = false;
  
    return jobCards;
  },

  async actionRemark({ commit, state }, { id }) {
    const list = await JobCardsService.getRemark(id);
    commit(ACTON_NAME.SET_REMARK, list);
    return list?.success;
  },

   actionItemView({ commit, state }, { data }) {
     commit(ACTON_NAME.SET_ITEM_VIEW, data);
     state.itemView = data;

  },
};

const getters = {
  getDropdown: (state) => state?.drowpdownList?.success || [],
  getDetail: (state) => state?.detail?.success || [],
  getListjobCards: (state) => {
    console.log(state.list.success);
    
    return state.list.success || {};
  },

  getLoading: (state) => {
    return state.loading;
  },

  getRemark: (state) => state.remarks.success,

  getItemView:(state)=>state.itemView
};

export default {
  namespaced: true, // Enables namespacing
  state,
  mutations,
  actions,
  getters,
};

<template>
  <div :class="iconClass" :style="{ width: size + 'px', height: size + 'px', marginRight: '0.3rem' }">
    <slot>
      <!-- Default SVG content if no slot content is provided -->
      <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 20 20" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.1483 5.46941C14.1483 3.0683 12.9556 1.66699 10.8137 1.66699H5.00211C2.8941 1.66699 1.66675 3.0683 1.66675 5.46941V13.6017C1.66675 16.0422 2.8941 17.412 5.00211 17.412H10.8137C11.1631 17.412 11.4873 17.3755 11.7851 17.3039L9.80214 15.1974C9.39509 14.765 9.09954 14.259 8.91547 13.7198H5.18933C4.9813 13.7513 4.78021 13.6332 4.66926 13.4364C4.55832 13.2317 4.55832 12.9719 4.66926 12.7751C4.78021 12.5704 4.9813 12.4602 5.18933 12.4838H8.68997C8.66243 11.6629 8.86908 10.8347 9.30991 10.1221H5.18933C4.89046 10.1221 4.64846 9.84651 4.64846 9.508C4.64846 9.16948 4.89046 8.89315 5.18933 8.89315H10.5015C11.6095 8.16918 12.9911 8.10404 14.1483 8.69775V5.46941ZM5.18933 5.3277H7.26197C7.56083 5.3277 7.80353 5.60324 7.80353 5.94097C7.80353 6.28815 7.56083 6.56368 7.26197 6.56368H5.18933C4.89046 6.56368 4.64846 6.28815 4.64846 5.94963C4.64846 5.61111 4.89046 5.33557 5.18933 5.33557V5.3277ZM14.1924 12.5942C14.3535 12.423 14.6148 12.423 14.776 12.5942L16.8184 14.7639L17.6883 13.828C18.593 12.7086 18.5462 11.0201 17.5479 9.95961C16.872 9.24161 15.9251 8.98692 15.0569 9.19555L12.7335 11.6643C12.4112 12.0067 12.4112 12.5618 12.7335 12.9042C13.0558 13.2465 13.5783 13.2465 13.9006 12.9042L14.1924 12.5942ZM13.5581 9.54862C12.5241 8.86993 11.1502 9.00692 10.2534 9.95961C9.20591 11.0723 9.20591 12.8763 10.2534 13.989L13.3171 17.2435C13.6393 17.5859 14.1619 17.5859 14.4842 17.2435L16.2349 15.3838L14.4842 13.5241L14.4245 13.5844C13.7766 14.2081 12.775 14.188 12.1499 13.5241C11.5054 12.8393 11.5054 11.7292 12.1499 11.0444L13.5581 9.54862Z"
          fill="currentColor"
        />
      </svg>
    </slot>
  </div>
</template>

<script>
  export default {
    name: 'IconLead',
    props: {
      iconClass: {
        type: String,
        default: '',
      },

      size: {
        type: [Number, String],
        default: 25,
      },
    },
  };
</script>

<style scoped>
  /* You can add any additional styles here */
</style>

import { PERMISSION } from '@/constants/permission.constant';

const List = () => import('@/views/jobcard/index.vue');
const Create = () => import('@/views/jobcard/Forms/Create.vue');
const Edit = () => import('@/views/jobcard/Forms/Edit.vue');

export const jobcardRoute = [
  {
    path: '/job-cards',
    name: 'list-job-cards',
    component: List,
    meta: { requiresAuth: false, permissions: [PERMISSION.DEFAULT] },
  },
  {
    path: '/job-cards/create',
    name: 'create-job-cards',
    component: Create,
    meta: { requiresAuth: false, permissions: [PERMISSION.DEFAULT] },
  },
  {
    path: '/job-cards/edit/:id',
    name: 'edit-job-cards',
    component: Edit,
    meta: { requiresAuth: false, permissions: [PERMISSION.DEFAULT] },
  },
];

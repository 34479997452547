import { TYPE_ACTION } from '@/constants/ConstantType';
import { PERMISSION } from '@/constants/permission.constant';

export const HEADER_TABLE = [
  { key: 'no', label: 'No', title: 'No' },
  {
    key: 'jobCardDate',
    label: 'Jobcard Date',
    title: 'Jobcard Date',
    visible: false,
    disabled: true,
  },
  {
    key: 'jobCardId',
    label: 'Jobcard ID',
    title: 'Jobcard ID',
    visible: false,
    disabled: true,
  },
  {
    key: 'customerName',
    label: 'Customer Name',
    title: 'Customer Name',
    visible: false,
    disabled: true,
  },
  {
    key: 'phoneNumber',
    label: 'Phone Number',
    title: 'Phone Number',
    visible: false,
    disabled: true,
  },
  {
    key: 'brand',
    label: 'Brand',
    title: 'Brand',
    visible: false,
    disabled: true,
  },
  {
    key: 'model',
    label: 'Model',
    title: 'Model',
    visible: false,
    disabled: true,
  },
  {
    key: 'vinNumber',
    label: 'Vin Number',
    title: 'Vin Number',
    visible: false,
    disabled: true,
  },
  {
    key: 'plateNumber',
    label: 'Plate Number',
    title: 'Plate Number',
    visible: false,
    disabled: true,
  },
  {
    key: 'mileage',
    label: 'Mileage',
    title: 'Mileage',
    visible: false,
    disabled: true,
  },
  {
    key: 'serviceType',
    label: 'Service Type',
    title: 'Service Type',
    visible: false,
    disabled: true,
  },
  {
    key: 'serviceAdvisor',
    label: 'Service Advisor',
    title: 'Service Advisor',
    visible: false,
    disabled: true,
  },
  {
    key: 'status',
    label: 'Status',
    title: 'Status',
    visible: false,
    disabled: true,
  },
  {
    key: 'action',
    visible: false,
    disabled: true,
    hide: true,
    thStyle: { textAlign: 'center' },
    tdClass: 'sticky-collumn',
    thClass: 'sticky-collumn',
    action: [
      {
        actionName: TYPE_ACTION.EDIT,
        permission: PERMISSION.DEFAULT,
      },
      {
        actionName: TYPE_ACTION.REMARK,
        permission: PERMISSION.DEFAULT,
      },
      {
        actionName: TYPE_ACTION.UPLOAD_FILE,
        permission: PERMISSION.DEFAULT,
      },
    ],
  },
];

export const STATUSES_JOBCARD = [
  {
    value: 5,
    text: 'Open Jobcard',
    color:'#ff9900',
    lookup: [
      { value: 15, text: 'Cancel' }
    ],
  },
  {
    value: 18,
    color:'#14447B',
    text: 'Open Quotation',
    lookup: [
      { value: 15, text: 'Cancel' }
    ],
  },
  {
    value: 6, text: 'Work In Progress (WIP)',color:'#3399ff',
    lookup: [
      { value: 6, text: 'Work In Progress (WIP)' },
      { value: 7, text: 'Partial Part Issue' },
      { value: 20, text: 'Full Part Issue' },
      { value: 8, text: 'Road Test' },
      { value: 9, text: 'QC' },
      { value: 10, text: 'Washing' },
      { value: 11, text: 'Ready for Delivery' },
      { value: 12, text: 'Delivered' },
      { value: 13, text: 'Car Collected' },
      { value: 19, text: 'Completed' },
    ]
  },
  {
    value: 7, text: 'Full Part Issue',color:'#ff9900',
    lookup: [
      { value: 7, text: 'Partial Part Issue' },
      { value: 8, text: 'Road Test' },
      { value: 9, text: 'QC' },
      { value: 10, text: 'Washing' },
      { value: 11, text: 'Ready for Delivery' },
      { value: 12, text: 'Delivered' },
      { value: 13, text: 'Car Collected' },
      { value: 19, text: 'Completed' },
    ]
  },
  {
    value: 7, text: 'Partial Part Issue',color:'#ffcc00',
    lookup: [
      { value: 7, text: 'Full Part Issue' },
      { value: 8, text: 'Road Test' },
      { value: 9, text: 'QC' },
      { value: 10, text: 'Washing' },
      { value: 11, text: 'Ready for Delivery' },
      { value: 12, text: 'Delivered' },
      { value: 13, text: 'Car Collected' },
      { value: 19, text: 'Completed' },
    ]
  },
  {
    value: 8, text: 'Road Test',color:'#66ccff',
    lookup: [
      { value: 7, text: 'Partial Part Issue', },
      { value: 7, text: 'Full Part Issue' },
      { value: 9, text: 'QC' },
      { value: 10, text: 'Washing' },
      { value: 11, text: 'Ready for Delivery' },
      { value: 12, text: 'Delivered' },
      { value: 13, text: 'Car Collected' },
      { value: 19, text: 'Completed' },
    ]
  },
  {
    value: 9, text: 'QC',color:'#3399ff',
    lookup: [
      { value: 7, text: 'Partial Part Issue', },
      { value: 7, text: 'Full Part Issue' },
      { value: 8, text: 'Road Test' },
      { value: 10, text: 'Washing' },
      { value: 11, text: 'Ready for Delivery' },
      { value: 12, text: 'Delivered' },
      { value: 13, text: 'Car Collected' },
      { value: 19, text: 'Completed' },
    ]
  },
  {
    value: 10, text: 'Washing',color:'',
    lookup: [
      { value: 7, text: 'Partial Part Issue', },
      { value: 7, text: 'Full Part Issue' },
      { value: 8, text: 'Road Test' },
      { value: 9, text: 'QC' },
      { value: 11, text: 'Ready for Delivery' },
      { value: 12, text: 'Delivered' },
      { value: 13, text: 'Car Collected' },
      { value: 19, text: 'Completed' },
    ]
  },
  {
    value: 11, text: 'Ready for Delivery',color:'#33cc33',
    lookup: [
      { value: 7, text: 'Partial Part Issue', },
      { value: 7, text: 'Full Part Issue' },
      { value: 8, text: 'Road Test' },
      { value: 9, text: 'QC' },
      { value: 10, text: 'Washing' },
      { value: 12, text: 'Delivered' },
      { value: 13, text: 'Car Collected' },
      { value: 19, text: 'Completed' },
    ]
  },
  {
    value: 12, text: 'Car Collected',color:'#006600',
    lookup: [
      { value: 19, text: 'Completed' },
    ]
  },
  {
   value: 19, text: 'Completed',color:'#003300',lookup:[]
  },
{ 
  value: 15, text: 'Canceled' ,color: "#C11D22",lookup:[]
}
]


export const headerTableExport=[
          {
            key: 'no',
            label: 'No',
            title: 'Number',
            visible: true,
            thStyle: { minWidth: 'fit-content' },
          },
          {
            key: 'jobcardID',
            label: 'Jobcard ID',
            title: 'Jobcard ID',
            visible: false,
            disabled: false,
          },
          {
            key: 'jobcardDate',
            label: 'Jobcard Date',
            title: 'Jobcard Date',
            visible: true,
            disabled: false,
          },
          {
            key: 'vinNumber',
            label: 'Vin Number',
            title: 'Vin Number',
            visible: false,
            disabled: false,
          },
          {
            key: 'customerName',
            label: 'Customer Name',
            title: 'Customer Name',
            visible: true,
            disabled: false,
          },
          {
            key: 'brand',
            label: 'Brand',
            title: 'Brand',
            visible: true,
            disabled: false,
          },
          {
            key: 'model',
            label: 'Model',
            title: 'Model',
            visible: true,
            disabled: false,
          },
          {
            key: 'year',
            label: 'Year',
            title: 'Year',
            visible: false,
            disabled: false,
          },
          {
            key: 'mileage',
            label: 'Mileage',
            title: 'Mileage',
            visible: false,
            disabled: false,
          },
          {
            key: 'plateNumber',
            label: 'Plate Number',
            title: 'Plate Number',
            visible: true,
            disabled: false,
          },
          {
            key: 'phoneNumber',
            label: 'Phone Number',
            title: 'Phone Number',
            visible: false,
            disabled: true,
          },
          {
            key: 'serviceType',
            label: 'Service Type',
            title: 'Service Type',
            visible: true,
            thStyle: { minWidth: 'fit-content' },
          },
          {
            key: 'serviceAdvisor',
            label: 'Service Advisor',
            title: 'Service Advisor',
            visible: true,
            thStyle: { minWidth: 'fit-content' },
          },
          { key: 'status', label: 'Status', title: 'Status', visible: true },
        ];

export const CONST_JOBCARD_STATUS={
  CANCELED:15,
}

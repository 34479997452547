// src/store/modules/dataModule.js
const ACTIO_NAME = {
  SET_BRAND: 'SET_BRAND',
  SET_MODEL: 'SET_MODEL',
  SET_COLOR: 'SET_COLOR',
  SET_SERVICE_TYPE: 'SET_SERVICE_TYPE',
  SET_SOURCE: 'SET_SOURCE',
};
import { configurationService, TYPE } from '@/services/ConfigurationSetup';

const state = {
  list: [],
  size: 10,
  perPage: 10,
  page: 0,
  loading: false,
  error: null,
  detail: {},
  positions: [],
  drowpdown: {
    sources: [],
    models: [],
    serviceTypes: [],
    colors: [],
    brands: [],
  },
};

const mutations = {
  SET_DATA(state, data) {
    state.list = data;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },

  SET_DETAIL(state, data) {
    state.detail = data;
  },

  SET_POSITION(state, listDropdown) {
    state.positions = listDropdown;
  },

  [ACTIO_NAME.SET_BRAND](state, listDropdown) {
    state.drowpdown.brands = listDropdown;
  },

  [ACTIO_NAME.SET_COLOR](state, listDropdown) {
    state.drowpdown.colors = listDropdown;
  },

  [ACTIO_NAME.SET_SERVICE_TYPE](state, listDropdown) {
    state.drowpdown.serviceTypes = listDropdown;
  },

  [ACTIO_NAME.SET_MODEL](state, listDropdown) {
    state.drowpdown.models = listDropdown;
  },

  [ACTIO_NAME.SET_SOURCE](state, listDropdown) {
    state.drowpdown.sources = listDropdown;
  },
};

const actions = {
  async listConfiguration({ commit, state }, params) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await configurationService.getConfigurationList({
        page: state.page || 0,
        size: state.size || 10,
        perPage: state.perPage,
        ...params,
      });
      commit('SET_DATA', response.contents);
    } catch (error) {
      commit('SET_ERROR', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async dropdowList() {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await configurationService.getConfigurationList(params);
      commit('SET_POSITION', response.contents);
    } catch (error) {
      commit('SET_ERROR', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async positionListAction({ commit, state }, params) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await configurationService.getDropdownConfigurations({
        page: state.page || 0,
        size: 100,
        perPage: state.perPage,
        ...params,
      });

      commit('SET_POSITION', response.contents);
      return response.contents;
    } catch (error) {
      commit('SET_ERROR', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  // ===============================
  // dropdowList fetch api
  // ================================

  //colors drowdown list fetch api
  async colorDrowpDownListAction({ commit, state }, params) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await configurationService.getDropdownConfigurations({
        page: state.page || 0,
        size: 100,
        perPage: state.perPage,
        ...params,
        type: TYPE.COLOR,
      });

      commit(ACTIO_NAME.SET_COLOR, response.contents);
      return response.contents;
    } catch (error) {
      commit('SET_ERROR', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  //souces drowdown list fetch api
  async sourceDrowpDownListAction({ commit, state }, params) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await configurationService.getDropdownConfigurations({
        page: state.page || 0,
        size: 100,
        perPage: state.perPage,
        ...params,
        type: TYPE.SOURCE,
      });

      commit(ACTIO_NAME.SET_SOURCE, response.contents);
      return response.contents;
    } catch (error) {
      commit('SET_ERROR', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  //models drowdown list fetch api
  async modelDrowpDownListAction({ commit, state }, params) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await configurationService.getDropdownConfigurations({
        page: state.page || 0,
        size: 100,
        perPage: state.perPage,
        ...params,
        type: TYPE.MODEL,
      });

      commit(ACTIO_NAME.SET_MODEL, response.contents);
      return response.contents;
    } catch (error) {
      commit('SET_ERROR', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  //service type drowdown list fetch api
  async serviceTypesDrowpDownListAction({ commit, state }, params) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await configurationService.getDropdownConfigurations({
        page: state.page || 0,
        size: 100,
        perPage: state.perPage,
        ...params,
        type: TYPE.SERVICE_TYPE,
      });

      commit(ACTIO_NAME.SET_SERVICE_TYPE, response.contents);
      return response.contents;
    } catch (error) {
      commit('SET_ERROR', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  //brand drowdown list fetch api
  async brandDrowpDownListAction({ commit, state }, params) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await configurationService.getDropdownConfigurations({
        page: state.page || 0,
        size: 100,
        perPage: state.perPage,
        ...params,
        type: TYPE.BRAND,
      });

      commit(ACTIO_NAME.SET_BRAND, response.contents);
      return response.contents;
    } catch (error) {
      commit('SET_ERROR', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getDetail({ commit, state }, { id, type }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    const resp = await configurationService.getConfigurationById(id, type);

    commit('SET_DETAIL', resp);
    return resp;
  },
};

const getters = {
  list: (state) => state.list,
  positionsList: (state) => state.positions,
  loading: (state) => state.loading,
  error: (state) => state.error,
  detail: (state) => state.detail,
  colorsDrowndownList: (state) => state.drowpdown.colors,
  modelsDrowndownList: (state) => state.drowpdown.models,
  brandsDrowndownList: (state) => state.drowpdown.brands,
  serviceTypesDrowndownList: (state) => state.drowpdown.serviceTypes,
  sourcesDrowndownList: (state) => state.drowpdown.sources,
  allDropdown: (state) => state.drowpdown,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

import { MODULE_NAME, PERMISSION } from '@/constants/permission.constant';
import api from '@/services/AxiosConfig';
import store from '@/store';
import { TYPE_ACTION } from '../constants/ConstantType';

export class IModelUserPermission {
  constructor(props) {
    this.id = props.id || null;
    this.moduleName = props.moduleName || null;
    this.orderNumber = props.orderNumber || null;
    this.action = props.action || [];
  }
}

export class UserPermissionService {
  static async apiGetUserPermission(authToken) {
    return api
      .get('/users/user-modules', {
        ...(authToken && { headers: { Authorization: `Bearer ${authToken}` } }),
      })
      .then((d) => {
        return d.data?.map((dt) => new IModelUserPermission(dt));
      })
      .catch((er) => {
        console.log(er);
        return [];
      });
  }

  static refreshUserPermission() {
    store.dispatch('userPermission/getPermissionApi');
  }

  static async getUserPermissions() {
    const userModules = await this.apiGetUserPermission();

    const moduleName = userModules.map((m) => m.moduleName) || [];
    const list = [...(userModules?.map((m) => m.action || [])?.flat() || []), ...moduleName] || [];

    list.some((w) =>
      [
        'SOURCES_READ',
        'SOURCES_CREATE',
        'SOURCES_UPDATE',
        'SOURCES_DELETE',
        'SERVICE_TYPE_READ',
        'SERVICE_TYPE_CREATE',
        'SERVICE_TYPE_UPDATE',
        'SERVICE_TYPE_DELETE',
        'POSITION_READ',
        'POSITION_CREATE',
        'POSITION_UPDATE',
        'POSITION_DELETE',
        'BRAND_READ',
        'BRAND_CREATE',
        'BRAND_UPDATE',
        'BRAND_DELETE',
        'MODEL_READ',
        'MODEL_CREATE',
        'MODEL_UPDATE',
        'MODEL_DELETE',
        'COLOR_READ',
        'COLOR_CREATE',
        'COLOR_UPDATE',
        'COLOR_DELETE',
        'TERM_CONDITIONS_READ',
        'TERM_CONDITIONS_CREATE',
        'TERM_CONDITIONS_UPDATE',
        'TERM_CONDITIONS_DELETE',
      ].includes(w)
    ) && list.push(MODULE_NAME.CONFIGURATION);

    !list?.includes(PERMISSION.DEFAULT) && list.push(PERMISSION.DEFAULT);

    return list;
  }

  static getPermissionsModule() {
    const userModules = store.getters['userPermission/getPermissionsList'] || [];
    userModules.sort((a, b) => a.orderNumber - b.orderNumber);

    return userModules;
  }

  static getPermissionsModulunique() {
    const permisionModule = this.getPermissionsModule()?.map((m) => m.moduleName?.replace(/_(CREATE|DELETE|UPDATE|READ)$/, ''));

    const uniqueNameModule = permisionModule?.filter((value, index, self) => self.indexOf(value) === index);

    return uniqueNameModule;
  }

  static async isAuthorize(actionName) {
    const useName = store.getters['getUsername'];
    let userPermissions = store.getters['userPermission/userPermissions'];
    const asyncGet = UserPermissionService.getUserPermissions()
      .then((resuserPermissions) => {
        store.commit('userPermission/setUserPermissions', {
          userName: useName,
          permissions: resuserPermissions,
        });
        userPermissions = resuserPermissions;
        return userPermissions;
      })
      .catch((e) => []);

    if (!userPermissions?.length) return (await asyncGet).includes(actionName);
    return userPermissions?.includes(actionName);
  }

  static isAuthorizeListHandle({ permissionModule, textAction = TYPE_ACTION }) {
    return this.isAuthorize(permissionModule) && textAction;
  }

  static isPermission({ permissionModule, textAction = TYPE_ACTION }) {
    const userModules = store.getters['userPermission/userPermissions'];
    return textAction;
  }
}

import { PERMISSION } from '@/constants/permission.constant';

export const serviceType = [
  {
    path: '/configurations/service-types/create',
    name: 'service-type-create',
    component: () => import('@/views/configurations/service-type/FormCreate.vue'),
    meta: { requiresAuth: true, permissions: [PERMISSION.SERVICE_TYPE_CREATE] },
  },

  {
    path: '/configurations/service-types/edit/:id',
    name: 'service-type-edit',
    component: () => import('@/views/configurations/service-type/FormEdit.vue'),
    meta: { requiresAuth: true, permissions: [PERMISSION.SERVICE_TYPE_UPDATE] },
  },
];

import { PERMISSION } from '@/constants/permission.constant';
import * as routeModule from '@/modules/index';
import store from '@/store'; // Adjust path as necessary
import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const routeModuleImport = Object.entries(routeModule)
  ?.map(([key, value]) => value)
  ?.flat();

const routes = [
  {
    path: '',
    component: () => import('@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '/',
        name: 'HomePage',
        component: () => import('@/views/HomeView.vue'),
        meta: { requiresAuth: true, permissions: [PERMISSION.DASHBOARD_READ] },
      },
      {
        path: '/about',
        name: 'AboutPage',
        component: () => import('@/views/AboutView.vue'),
        meta: { requiresAuth: true, permissions: [PERMISSION.DEFAULT] },
      },
      {
        path: '/error-404',
        name: 'error',
        meta: { requiresAuth: true, permissions: [PERMISSION.DEFAULT] },
        component: () => import('@/views/auth/Error404.vue'),
      },
      ...routeModuleImport,
    ],
  },

  {
    path: '/login',
    component: () => import('@/layouts/LoginLayout.vue'),
    children: [
      {
        path: '',
        name: 'LoginPage',
        component: () => import('@/views/auth/Login.vue'),
      },
    ],
  },

  {
    path: '/no-permission',
    name: 'NoPermission',
    component: () => import('@/views/auth/NoPermission.vue'),
  },

  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = store.getters.isLoggedIn;
  const [actionName] = to.meta?.permissions || [];
  const isAuthorize = true;

  if (to.fullPath.includes('//')) {
    const correctedPath = to.fullPath.replace(/\/\//g, '/');
    next(correctedPath);
  }
  if (requiresAuth) {
    if (!isAuthenticated) {
      if (to.path !== '/login') {
        next('/login');
      } else {
        next(false);
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;

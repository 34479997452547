import { PERMISSION } from '@/constants/permission.constant';

const List = () => import('@/views/leads/index.vue');
const Create = () => import('@/views/leads/Forms/Create.vue');
const Edit = () => import('@/views/leads/Forms/Edit.vue');

export const roleRoute = [
  {
    path: '/leads',
    name: 'list-leads',
    component: List,
    meta: { requiresAuth: false, permissions: [PERMISSION.LEADS_READ] },
  },
  {
    path: '/leads/create',
    name: 'create-leads',
    component: Create,
    meta: { requiresAuth: false, permissions: [PERMISSION.LEADS_CREATE] },
  },
  {
    path: '/leads/edit/:id',
    name: 'edit-leads',
    component: Edit,
    meta: { requiresAuth: false, permissions: [PERMISSION.LEADS_READ] },
  },
];

// import { uploadFile, fetchFiles } from '@/services/files/files-upload';

import { fetchFiles, uploadFile } from "./files-upload.service";

const state = {
  files: [],
  loading: false,
  error: null,
};

const getters = {
  allFiles: (state) => state.files,
  isLoading: (state) => state.loading,
  fetchError: (state) => state.error,
};

const actions = {
  async uploadFile({ commit }, fileData) {
    commit('setLoading', true);
    try {
      const data = await uploadFile(fileData);
      commit('addFile', data);
     return data;
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  
  async fetchFiles({ commit },fileName) {
    commit('setLoading', true);
    try {
      const data = await fetchFiles(fileName);
      commit('setFiles', data);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
};

const mutations = {
  setFiles: (state, files) => (state.files = files),
  addFile: (state, file) => state.files.push(file),
  setLoading: (state, loading) => (state.loading = loading),
  setError: (state, error) => (state.error = error),
};

export default {
  namespaced: true, // Enables namespacing
  state,
  getters,
  actions,
  mutations,
};
<template>
    <div :class="iconClass" :style="{ width: size + 'px', height: size + 'px', marginRight: '0.3rem' }">
        <slot>
            <!-- Default SVG content if no slot content is provided -->
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M14.8075 6.98483H18.3334C18.3334 4.15383 16.6371 2.5 13.763 2.5H6.2371C3.36303 2.5 1.66673 4.15383 1.66673 6.94872V13.0513C1.66673 15.8462 3.36303 17.5 6.2371 17.5H13.763C16.6371 17.5 18.3334 15.8462 18.3334 13.0513V12.7913H14.8075C13.1711 12.7913 11.8445 11.4979 11.8445 9.9025C11.8445 8.30707 13.1711 7.01372 14.8075 7.01372V6.98483ZM14.8073 8.22701H17.711C18.0547 8.22701 18.3332 8.49861 18.3332 8.83365V10.9425C18.3292 11.2759 18.053 11.5452 17.711 11.5491H14.874C14.0455 11.56 13.3211 11.007 13.1332 10.2203C13.0391 9.73191 13.1712 9.22797 13.4941 8.84351C13.817 8.45905 14.2977 8.23339 14.8073 8.22701ZM14.9333 10.4441H15.2074C15.5592 10.4441 15.8444 10.1661 15.8444 9.82304C15.8444 9.48003 15.5592 9.20196 15.2074 9.20196H14.9333C14.765 9.20003 14.603 9.26385 14.4833 9.37918C14.3636 9.49451 14.2963 9.65176 14.2963 9.81582C14.2962 10.16 14.5803 10.4402 14.9333 10.4441ZM5.61494 6.98481H10.3186C10.6705 6.98481 10.9557 6.70674 10.9557 6.36373C10.9557 6.02071 10.6705 5.74264 10.3186 5.74264H5.61494C5.26599 5.74262 4.98196 6.01631 4.97791 6.3565C4.97788 6.70071 5.26192 6.98086 5.61494 6.98481Z"
                    fill="#4B4B4B" />
            </svg>
        </slot>
    </div>
</template>

<script>
    export default {
        name: 'IconWallet',
        props: {
            iconClass: {
                type: String,
                default: '',
            },

            size: {
                type: [Number, String],
                default: 25,
            },
        },
    };
</script>
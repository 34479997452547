export function formatCurrency(value, locale = 'en-US', currency = 'USD') {
  if (typeof value !== 'number') {
    console.warn('Value passed to formatCurrency is not a number.');
    return value;
  }
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(value);
}

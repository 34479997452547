const state = {
  activeMenuItem: localStorage.getItem('activeMenuItem') || 'Home',
};

const mutations = {
  setActiveMenuItem(state, menuItem) {
    state.activeMenuItem = menuItem;
    localStorage.setItem('activeMenuItem', menuItem);
  },
};

const actions = {
  setActiveMenuItem({ commit }, menuItem) {
    commit('setActiveMenuItem', menuItem);
  },
};

const getters = {
  activeMenuItem: (state) => state.activeMenuItem,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

import api from "@/services/AxiosConfig";


const API_URL = "files";

export const uploadFile = async (fileData) => {
  try {
    const response = await api.post(`${API_URL}/upload`, fileData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
}

    export async function downloadAllFiles(idFile, fileType) {
      return api
        .get(`${API_URL}/${idFile}`, { responseType: 'blob' })
        .then((res) => {
          const contentDisposition = res.headers['content-disposition'];
          let fileName = 'downloaded_file';
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
          }
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
            const fileNameParts = idFile.split('.');
            const name = fileNameParts.slice(0, -1).join('.');
            const extension = fileNameParts.slice(-1)[0];
            link.setAttribute('download', `${name}.${extension}`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          return { success: 'File downloaded successfully' };
        })
        .catch((er) => {
          return { error: er };
        });
    }


export const fetchFiles = async (fileName) => {
  try {
  
    
    const response = await api.get(`${API_URL}/${fileName}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching files:', error);
    throw error;
  }
};

    
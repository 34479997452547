import router from '@/router';
import Swal from 'sweetalert2';

/**
 * Displays a SweetAlert error message.
 * @param {string} title - The title of the alert.
 * @param {string} text - The text of the alert.
 */
function showSwalError(title, text) {
  Swal.fire({
    icon: 'error',
    title: title,
    text: text,
  });
}

/**
 * Handles HTTP response errors.
 * @param {number} status - The HTTP status code.
 * @param {string} message - The error message.
 */
function handleHttpResponseError(status, message) {
  let title = 'Error';
  let text = message || 'An error occurred';

  switch (status) {
    case 400:
      title = 'Bad Request';
      text = 'Invalid request';
      break;
    case 401:
      title = 'Unauthorized';
      text = 'You are not authorized to perform this action';
      break;
    case 403:
      title = 'Forbidden';
      text = 'Access denied';
      break;
    case 404:
      title = 'Not Found';
      text = 'Resource not found';
      break;
    case 422:
      title = 'Unprocessable Entity';
      text = 'Validation error: one or more fields are required';
      break;
    case 500:
      title = 'Internal Server Error';
      text = 'Something went wrong on the server';
      break;
    default:
      title = 'Error';
      text = 'An unexpected error occurred';
      break;
  }

  // showSwalError(title, text);
  if (status === 401) handleLogoutAndRedirect();
}

/**
 * Handles logout and redirects to the login page.
 */
function handleLogoutAndRedirect() {
  router.push('/error-404');
}

/**
 * Centralized error handling function.
 * @param {Object} error - The error object.
 */
export function handleError(error) {
  if (!error) {
    // showSwalError("Unknown Error", "An unknown error occurred.");
    return;
  }

  if (error.response) {
    const { status, data } = error.response;
    // handleHttpResponseError(status, data.message);
  } else if (error.request) {
    handleLogoutAndRedirect();
  } else if (error.message && (error.message.includes('Network Error') || error.code === 'ERR_NETWORK')) {
    // showSwalError(
    //   "Network Error",
    //   "No response received from the server. Please check your network connection and try again."
    // );
    handleLogoutAndRedirect();
  } else {
    // showSwalError("Error", error.message || "An unexpected error occurred.");
  }
}

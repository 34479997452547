import { PERMISSION } from '@/constants/permission.constant';

export const colors = [
  {
    path: '/configurations/colors/create',
    name: 'colors-create',
    component: () => import('@/views/configurations/colors/FormCreate.vue'),
    meta: { requiresAuth: true, permissions: [PERMISSION.COLOR_CREATE] },
  },

  {
    path: '/configurations/colors/edit/:id',
    name: 'colors-edit',
    component: () => import('@/views/configurations/colors/FormEdit.vue'),
    meta: { requiresAuth: true, permissions: [PERMISSION.COLOR_UPDATE] },
  },
];

import { filterMenu } from '@/constants/menu.constant';
import { PERMISSION } from '@/constants/permission.constant';
import store from '@/store';
import { addressYescar } from './address-yescar.route';
import { branchs } from './branch.route';
import { colors } from './color.route';
import { models } from './model.route';
import { position } from './position.route';
import { serviceType } from './service-type.route';
import { sources } from './source.route';
import { termCondition } from './term-condition';

const findAllPermission = store.getters['userPermission/getPermissions'];

const isRedirectRoute = (findAllPermission?.data || []).map((permission) => permission.action)?.flat();

const isRedirect = filterMenu(isRedirectRoute);
const [, , , , configurations] = isRedirect || {};
const { children } = configurations || {};
const [firstChild] = children || [];

export const configurationsRoute = [
  {
    path: '/configurations',
    name: 'configurations',
    meta: { requiresAuth: true, permissions: [PERMISSION.DEFAULT] },
    component: () => import('@/views/configurations/Index.vue'),
    redirect: `${firstChild?.path}`,
    children: [
      {
        path: '/configurations/positions',
        name: 'positions',
        component: () => import('@/views/configurations/positions/List.vue'),
        meta: { requiresAuth: true, permissions: [PERMISSION.POSITION_READ] },
      },

      {
        path: '/configurations/sources',
        name: 'sources',
        component: () => import('@/views/configurations/sources/List.vue'),
        meta: { requiresAuth: true, permissions: [PERMISSION.SOURCES_READ] },
      },

      {
        path: '/configurations/service-types',
        name: 'service-types',
        component: () => import('@/views/configurations/service-type/List.vue'),
        meta: {
          requiresAuth: true,
          permissions: [PERMISSION.SERVICE_TYPE_READ],
        },
      },

      {
        path: '/configurations/brands',
        name: 'brands',
        component: () => import('@/views/configurations/brands/List.vue'),
        meta: { requiresAuth: true, permissions: [PERMISSION.BRAND_READ] },
        props: { defaultProp: 'defaultValue' }, // Pass default props here
      },

      {
        path: '/configurations/models',
        name: 'models',
        component: () => import('@/views/configurations/models/List.vue'),
        meta: { requiresAuth: true, permissions: [PERMISSION.MODEL_READ] },
        props: { defaultProp: 'defaultValue' }, // Pass default props here
      },

      {
        path: '/configurations/colors',
        name: 'colors',
        component: () => import('@/views/configurations/colors/List.vue'),
        meta: { requiresAuth: true, permissions: [PERMISSION.COLOR_READ] },
        props: { defaultProp: 'defaultValue' }, // Pass default props here
      },

      {
        path: '/configurations/term-condition',
        name: '/term-condition',
        component: () => import('@/views/configurations/term-condition/TermConditionList.vue'),
        meta: {
          requiresAuth: true,
          permissions: [PERMISSION.TERM_CONDITIONS_READ],
        },
        props: { defaultProp: 'defaultValue' }, // Pass default props here
      },
    ],
  },
  ...position,
  ...colors,
  ...models,
  ...serviceType,
  ...sources,
  ...branchs,
  ...termCondition,
  ...addressYescar,
];
